@import "react-notifications-component/dist/theme.css";
@import "./callout.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// * {
//   scrollbar-width: thin;
//   scrollbar-color: #9c9c9c transparent;
// }

*::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #9c9c9c;
  border-radius: 20px;
}


.ms-TextField-field {
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	&[type="number"] {
		-moz-appearance: textfield;
		&:hover,
		&:focus {
			-moz-appearance: number-input;
		}
	}
}

.app {
  height: 100vh;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.error-page {
  &-content {
    margin: 20px;
    text-align: center;
  }

  .loading-block {
    overflow: initial;
  }
}

.no-data {
  white-space: pre-line;
  margin: 10px 50px;
}

.rnc__base {
	z-index: 10000000;
}

.ms-Checkbox.is-disabled {
  .ms-Checkbox-checkbox {
    background: rgb(243, 242, 241);
  }
  &.is-checked{
    .ms-Checkbox-checkbox {
      .ms-Checkbox-checkmark{
        color: rgb(0, 150, 136);
      }
    }
  }
}

label.upload-button.disabled{
  color:  #bebebe;
}

.qty-info {
  margin-left: 4px;
  margin-top: 1px;
}

.sticky-markers {
  position: sticky;
  left: 0;
  display: flex;
  z-index: 2;
}

[class|=stickyAbove] {
  z-index: 13;
}

.stickyColumn {
  position: sticky !important;
  background: inherit;
  left: 11px;
  z-index: 1;
}

.stickyHeaderColumn {
  position: sticky !important;
  background: #d5eae7;
  left: 11px;
  z-index: 1;
}

.stickyCheckbox {
  position: sticky !important;
  left: 0;
  z-index: 1;
}

.ms-DetailsRow {
  &.is-selected {
    .stickyColumn {
      background: inherit;
    }
  }
}

.stickyCheckboxesTable {
  .ms-DetailsRow-fields {
    background: inherit;
  }
  .ms-DetailsHeader-cellIsCheck {
    position: sticky;
    background: inherit;
    left: 0;
    z-index: 1;
  }
  .ms-DetailsRow-cellCheck {
    position: sticky !important;
    background: inherit;
    left: 0;
    margin: 0 1px;
    z-index: 1;
  }
  .ms-DetailsRow {
    .ms-DetailsRow-check {
      opacity: 1;
      .ms-Check {
        opacity: 0;
      }
    }
    &.is-selected {
      .ms-Check {
        opacity: 1;
      }
    }
    &:hover {
      .ms-Check {
        opacity: 1;
      }
    }
  }
}

.page-from-dashboard-wrapper-content {
  height: 100%;
}
