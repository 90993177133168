@import "styles/constants.scss";

.actionBtn {
  padding: 0;
  height: 100%;
  opacity: 0;
  :global {
    i.ms-Button-menuIcon {
      display: none;
    }
  }
}

.editColumn {
  max-width: 100%;
  display: flex;
  align-items: center;
  :global {
    i {
      margin-left: 5px;
      opacity: 0;
    }
  }
  .editColumnContent{
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

:global {
  .ms-DetailsHeader-check {
    opacity: 1;
  }
}

.table {
  overflow: initial;
  :global {
    .ms-DetailsHeader-cell span {
      width: 100%;
    }
    .ms-List-cell {
      border-bottom: 1px solid #f0efef;
    }
    .ms-DetailsRow {
      user-select: auto;
      &-cell {
        position: static;
        display: flex;
        align-items: center;
        span {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .cell-action-btn {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
      }
      .event-display {
        display: flex;
        .ms-TooltipHost {
          margin-left: 5px;
          display: flex;
          align-items: center;
          i {
            font-weight: bold;
          }
        }
      }
      .ms-DetailsRow-check {
        padding-left: 20px;
      }
      .ms-TextField {

        &-fieldGroup {
          height: 20px;
          border: 1px solid $backgroundGrey;
          input {
            font-size: 12px;
            padding: 0 5px;
          }
        }
      }
      .ms-ComboBox {
        height: 20px !important;
        border: 1px solid $backgroundGrey !important;
        border-radius: 2px !important;
        &::after {
          border-width: 0;
        }
      }
      .statuses {
        display: flex;
        align-items: center;
        & > div {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          display: block;
          margin-right: 3px;
        }
      }
      cursor: pointer;

      &.is-selected,
      &:hover {
        .statuses .status {
          background-color: transparent;
        }
        :local {
          .editColumn,
          .actionBtn {
            opacity: 1;
            i {
              opacity: 1;
              color: $primaryColor;
            }
          }
        }
      }
      .ms-ComboBox-container {
        .ms-ComboBox {
          height: 22px;
          border-radius: 4px;
          font-size: 12px;
        }
      }
    }
  }
}

.loadingMore {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  right: 1%;
  width: 93%;
  height: 40px;
  background: linear-gradient(rgba($white, 0.2), rgba($white, 0.7));
  &OnPanel {
    bottom: 65px;
    right: auto;
  }
}

.visibleSelectionTable {
  :global {
    .ms-DetailsRow-check {
      opacity: 1;
    }
  }
}