@import 'styles/constants.scss';

.pageWrapperDetails {
  position: relative;
  height: calc(100% - 90px);
  margin-top: 10px;
  flex: 1;
  &.heigth105{
    height: calc(100% - 105px);
  }
  :global {
    .ms-ScrollablePane {
      // &--contentContainer {
      //   position: relative;
      // }
      .ms-DetailsHeader {
        background-color: $secondaryColor;
        padding-top: 0;
        .ms-DetailsHeader-cell.is-actionable {
          .ms-DetailsHeader-cellName > div {
            display: flex;
            i {
              margin-right: 5px;
            }
          }
        }
        .ms-DetailsRow-check.ms-DetailsHeader-check.ms-DetailsRow-check--isHeader.ms-Check-checkHost {
          padding-left: 20px;
        }
      }
    }
  }
}
