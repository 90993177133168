.technicalConclusionModal {
  :global {
    .ms-Dialog-main {
      width: 800px;
      max-width: 800px;
      overflow: hidden;
      .actions {
        margin: 15px 24px;
        &>button {
          margin-right: 10px;
        }
      }
      .basic-modal-content {
        padding-top: 0;
        height: calc(100% - 70px);
        overflow-y: auto;
      }
      .loading-block {
        height:425px!important;
        max-height: 425px!important;
			}
    }
  }
  .flexColumn {
    flex-direction: column;
    align-items: inherit;
  }

  .smallSizeLabel {
    flex: 0.355 1;
  }

  .flexColumn {
    flex-direction: column;
    align-items: inherit;
  }

  .flexInherit {
    flex: inherit;
  }
  .flexEnd {
   justify-content: flex-end;
  }
}