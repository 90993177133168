.dashboardPage {
  padding: 20px;
}
.dashboard{
  height: calc(100% + 20px);
}
.widgetWrapper {
  display: grid;
  gap: 20px 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
