.repairConstCalculationModal {
  :global {
    .ms-Dialog-main {
      width: 800px;
      max-width: 800px;
    }
    .actions {
      margin-bottom: 10px;
      &>button {
        margin-right: 10px;
      }
    }
    .scrollable-warapper{
      position: relative;
      height: 200px;
      .ms-ScrollablePane--contentContainer{
        overflow-x: hidden;
      }
    }
  }
  .notificationBlock {
    width: 90%;
    margin-top: 30px;
  }
}