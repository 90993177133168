.elementsContainer {
    display: flex;
    gap: 20px;
    height: 100%;
}

.modal {
    :global {
        .ms-Dialog-main {
            min-width: auto;
            max-width: 1200px;
            height: 80vh;
            .basic-modal-content {
				height: calc(80vh - 180px);
				overflow-y: auto;
			}
            .scrollable-warapper{
				position: relative;
				height: 85%;	
			}
            .scrollable-table-wrapper {
				position: relative;
                width: 100%;
                min-width: 300px;
            }
        }
        .ms-Viewport {
            height: 100%;
        }
        .ms-DetailsList {
            height: 100%;
        }
        .ms-DetailsList > div {
            height: 100%;
            display: flex;
            flex-direction: column;
        }
    }
}

.buttonSection {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-self: center;
}