@import 'styles/constants.scss';

.basicModal {
	:global {
		.ms-Dialog-main {
			height: auto;
			min-width: 400px;
			max-width: 500px;
			.drag-handle {
				cursor: move;
			}
			.flex-row {
				align-items: center;
				&.flex-start {
					align-items: flex-start;
				}
			}
		}
		.ms-Modal-scrollableContent {
			overflow-y: initial;
		}
	}
	&Header {
		position: sticky;
		top: 0;
		z-index: 10;
	}
	&Title {
		color: $white;
		font-size: 20px;
		font-weight: 600;
		line-height: 27px;
		padding: 20px 24px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 0;
		background-color: $primaryLightColor;
	
		i {
			color: $darkGrey;
			font-size: 14px;
			cursor: pointer;
		}
	}
	&Content {
		padding: 20px 24px;
		min-height: 50px;
	}
	&Columns {
		display: flex;
	}
	&Column {
		flex: 1;
		&:first-child {
			margin-right: 20px;
		}
	}
	&Footer {
		padding: 20px 24px;
		display: flex;
		justify-content: space-between;
    background-color: $lightGrey;
	}
}

.bottomBlock {
	position: sticky;
	bottom: 0;
	z-index: 11;
}

.submitButton {
	margin-right: 20px;	
}
