
$primaryColor: #009688;
$primaryLightColor: #7fcac3;
$secondaryColor: #d5eae7;

$green: #80ff00;
$aqua: #83ffff;
$orange: #ff8000;
$red: #f44336;
$lightRed: #ff6464;
$lightBackgroundRed: #fcf2f3;
$lightBackgroundBlue: #fafaff;
$lightBackgroundGreen: #f8fdf2;
$blue: #3662f4;
$greenText: #387000;

$white: #fff;
$black: #000;
$darkGrey: #323130;
$lightGrey: #f5f5f5;
$backgroundGrey: #dcdcdc;
$violet: #9e36f4;