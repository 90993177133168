@import 'styles/constants.scss';

.docStatusIcon {
    font-size: 20px;
    &Minus {
        color: $red;
    }
    &Plus {
        color: $blue;
    }
    &Iternal {
        color: $violet;
    }
}

.legend {
    &Item {
        display: flex;
        align-items: center;
        margin: 5px;
        gap: 5px;
    }
}

.companyInput {
    width: 180px;
}

.searchNumDocInput {
    width: 190px !important;
}

.invoiceTypeInput {
    width: 200px;
}

.statusInput {
    width: 160px;
}

.amount {
    text-align: right;
    width: 100%;
}