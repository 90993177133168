.confirmModal {
  :global {
    .ms-Dialog-main {
      width: 360px;
      height: auto;
      min-height: auto;

      .errors-block {
        position: absolute;
        bottom: 62px;
      }
    }
  }
  &.with-warning {
    .panel-content {
      margin-bottom: 30px;
    }
  }
  &Content {
    padding: 24px;
    position: relative;
    height: 100%;
  }
  &Footer {
    display: flex;
    justify-content: space-between;
    background-color: #f5f5f5;
    padding: 15px 24px;
    min-height: 32px;
  }
}

