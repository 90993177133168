@import 'styles/constants.scss';

.list {
  margin: 0;
  padding-left: 0;
  height: 100%;
  overflow-y: auto;
}

.pointIcon {
  position: absolute;
  top: 8px;
  left: 12px;
  z-index: 2;
  cursor: pointer;
  font-size: 22px;
  color: $black;
}

.mobileIcon {
  left: 18px;
  top: 10px;
}

.linkExpandedBlock {
  padding-left: 10px;
  &Expanded {
    background-color: $backgroundGrey;
    &:before {
      content: "";
      height: 12px;
      width: 100%;
      background: $backgroundGrey;
      position: absolute;
      left: 0;
      top: -2px;
    }
    &:after {
      content: "";
      height: 10px;
      width: 100%;
      background: $backgroundGrey;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}

.listPoint {
  :global {
    .link-expanded-block {
      &-title {
        justify-content: space-between;
        padding-left: 30px;
      }
      ul {
        padding-left: 0;
        margin-top: 10px;
        li .link-expanded-block {
          padding-left: 20px;
          li.is-active {
            left: -30px;
            padding-left: 26px;
          }
        }
      }
    }
  }
}

.listPointLink,
.linkExpandedBlockTitle{
  align-items: center;
  padding-left: 10px;
  color: $darkGrey;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  &Expanded {
    font-weight: bold;
  }
}

.listPointLinkIcon {
  margin-right: 10px;
  cursor: pointer;
  font-size: 22px;
  color: $black;
}

.iconWrapper {
  display: flex;
  align-items: center;
  position: relative;
  left: -20px;
  :global {
    i {
      margin-right: 10px;
      cursor: pointer;
      font-size: 22px;
      color: $black;
    }
  }
}

.itemLink {
  padding-left: 50px;
}

.iconExpand {
  margin-right: 20px;
  padding-top: 1px;
}

.item {
  list-style-type: none;
  padding: 10px 0;
  position: relative;
  &:hover {
    cursor: pointer;
  }
  &ActiveIcon {
    background-color: #dadada;
    border-right: 2px solid $primaryColor;
  }
  &IsActive {
    background-color: #cfcfcf;
    position: relative;
    width: 100%;
    left: -10px;
    padding-left: 6px;
    border-left: 4px solid $primaryColor;
    display: flex;
    align-items: center;
    & > a,
    & > div > a {
      color: $black;
      font-weight: bolder;
    }
  }
  &IsDisabled .link-expanded-block-title,
  &IsDisabled a,
  &IsDisabled > i {
    opacity: 0.4;
    pointer-events: none;
    font-weight: normal;
  }
}

.homeIcon {
  padding: 0;
  background-color: transparent;
  :global {
    i {
      font-size: 22px;
    }
    .ms-Icon-imageContainer {
      width: 20px;
      height: 20px;
      .ms-Image {
        justify-content: flex-end;
        display: flex;
        align-items: center;
        .ms-Image-image {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

.modeBtn {
  display: flex;
  align-items: center;
}

.modeBtnIcon {
  font-size: 14px;
  cursor: pointer;
}

.settings {
  padding: 10px 0 10px 10px;
  border-top: 1px solid #b9b9b9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  :global {
    .ms-Button i {
      color: $black;
      font-size: 16px;
    }
  }
}

.tooltipTitle {
  font-weight: bold;
  font-size: 14px;
  cursor: default;
}

.tooltipMenu {
  :global {
    left: 48px !important;
    div.tooltip-content {
      padding: 5px 10px;
    }
    ul {
      padding: 0 0 5px;
      margin: 0;
      list-style-type: none;
      li {
        font-size: 14px;
        padding: 8px 15px 0;
        cursor: default;
        a {
          font-size: 14px;
          color: $darkGrey;
          &:hover {
            cursor: pointer;
          }
        }
        ul {
          padding: 0;
        }
      }
    }
  }
}

.leftMenu {
  border-right: 1px solid #c7c7cc;
  background-color: #eeeeee;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .operationsIconsWrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 5px 7px;
    .operationsIcon{
      height: 22px;
      width: 22px;
      margin-bottom: 7px;
      padding-left: 15px;
      cursor: pointer;
      &Active {
        border-right: 2px solid $primaryColor;
      }
    }
      i{
       font-size: 20px;
    }

  }
}
