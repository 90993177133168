@import "styles/constants.scss";

.title {
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  font-size: 20px;
  margin-bottom: 2px;
}

.itemsCount {
  margin-left: 10px;
  font-size: 14px;
  color: rgb(50, 49, 48);
}

.itemsRefresh {
  margin-left: 10px;
  font-size: 14px;
  cursor: pointer;
  :global {
    .ms-TooltipHost {
      display: flex;
      align-items: center;
    }
  }
}

.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $white;

  & > div {
    display: flex;
    align-items: center;
  }
}

.errorsWrapper{
  :global{
    i.ms-Icon{
      color: red;
    }
  }
}
