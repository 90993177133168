.comboBox {
	:global {
		.ms-ComboBox {
			&-Input {
				cursor: pointer;
				&::selection {
					user-select: none;
				}
			}
		}
	}
}