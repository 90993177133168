@import "styles/constants.scss";

.legend {
    display: flex;
    font-size: 14px;
    cursor: pointer;
    margin-right: 10px;
    i {
      color: $primaryColor;
      font-weight: bold;
      margin-right: 5px;
      font-size: 18px;
    }
    &Content {
      display: flex;
      flex-wrap: wrap;
      & > div {
        width: 50%;
        display: flex;
        align-items: center;
        margin: 5px 0;
        font-size: 14px;
      }
    }
    &Marker {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      // border: 1px solid $backgroundGrey;
      margin-right: 5px;
    }
    &Rectangular {
      width: 5px;
      height: 30px;
      margin-right: 5px;
    }
    &Text {
      margin-right: 20px;
    }
  }
  .serviceLegend{
    min-width: 415px;
    .servicePageLegend{
      display: flex;
      align-items: flex-start;
      .legendColumn{
        margin-right: 15px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        & > div {
          display: flex;
          align-items: center;
          margin: 5px 0;
          font-size: 14px;
          width: 90px;
        }
      }
    }
  }
  