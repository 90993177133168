.tnInfoContainer {
    :global {
        .ms-TextField-errorMessage {
            white-space: break-spaces;
        }
    }
}

.modal {
    :global {
        .ms-Modal-scrollableContent {
            width: 450px;
        }
    }
    min-width: 450px !important;
}