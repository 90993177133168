@import "styles/constants.scss";

.commandBar {
  padding: 3px 10px;
  border-bottom: 1px solid $lightGrey;
  background-color: $white;
  height: 46px;
  display: flex;
  align-items: center;
  overflow-x: auto;

  & > div {
    width: 100%;
    :global {
      .ms-CommandBar {
        padding: 0;
      }
    }
  }
  :global {
    .ms-Button {
      &.is-disabled i {
        color: rgb(161, 159, 157);
      }
    }

    .ms-Dropdown-container {
      width: 140px;
      display: inline-block;
      position: relative;
      display: flex;
      align-items: center;

      .is-disabled {
        .ms-Dropdown-title,
        i {
          background-color: transparent;
          color: rgb(161, 159, 157);
        }
      }
    }

    .ms-Dropdown-title {
      border: 0;
      padding-left: 10px;
      display: flex;
      align-items: center;
      color: $darkGrey;

      & > div {
        display: flex;
        align-items: center;
      }

      i {
        padding-right: 4px;
        color: $primaryColor;
        font-size: 16px;
      }
    }
  }
}
