.panelTabs {
	display: flex;
	height: 50px;

	&Tab {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 15px 0;
		background-color: #f5f5f5;
		cursor: pointer;
		&Active {
			background-color: #fff;
			font-weight: bold;
			position: relative;
			&::after{
				content: " ";
				width: 90%;
				border-bottom: 2px solid rgb(0, 150, 136);
				position: absolute;
				bottom: 0;
			}
		}

		&Disabled {
			pointer-events: none;
			opacity: 0.5;
		}
	}
}