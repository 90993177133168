.addFaq {
  :global{
    .ms-Dialog-main {
      max-width: 600px;
      width: 600px;
    }
  }
  .editor {
    width: 100%;
    font-weight: normal;
    :global {
      .ql-container {
        .ql-editor {
          min-height: 210px;
          img {
            max-width: 100%;
            height: 240px;
            object-fit: contain;
          }
        }
      }
    }
  }
  .themeInput{
    width: 100%;
  }
  .flexColumn {
    flex-direction: column;
    align-items: flex-start;
  }

}