.infoContainer {
  :global {
    .ms-TextField {
      &-suffix {
        background-color: transparent;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  display: flex;
  align-items: center;
  margin: 10px 0;
  gap: 10px;


  &Label {
    flex: 1;
    min-width: 100px;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
  }

  &Value {
    flex: 1.5;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}