@import 'styles/constants.scss';

.basicPanel {
	:global {
		.ms-Panel-scrollableContent {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			overflow: hidden;
		}
		.ms-Panel-commands {
			margin-top: 0;
			padding: 20px 0;
			background-color: $primaryLightColor;
		}
		.ms-Panel-navigation{
			display: flex;
			justify-content: flex-start;
			align-items: center;
			.ms-Panel-header{
				flex-grow: 0;
				padding-right: 10px;
				.ms-Panel-headerText {
					color: $white;
				}
			}
			.status-marker{
				width: 12px;
				height: 12px;
				margin-top: 3px;
				border-radius: 50%;
				border: 1px solid $white;
			}
			.ms-Panel-closeButton{
				position: absolute;
				right: 0;
			}
		}
		.ms-Panel-content {
			padding: 0;
			height: 100%;
			display: flex;
			flex-direction: column;
			position: relative;
			& > div {
				height: 100%;
			}
			.message-block {
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				height: auto;
			}
		}
		.ms-Panel-footer {
			background-color: $lightGrey;
			display: flex;
			z-index: 1;
			&Inner {
				width: 100%;
				& > div {
					display: flex;
					justify-content: space-between;
				}
			}
		}
	}
}

.basicPanelActions {
	padding: 24px 0 0;
	margin: 0 24px;
	display: flex;
	align-items: flex-end;
	overflow-x: scroll;
	width: auto;
	label, &Btn {
		background-color: $lightGrey;
		color: $darkGrey;
		height: 40px;
		margin-right: 10px;
		min-width: 100px;
		max-width: 140px;
		font-size: 12px;
		padding: 0;
		border: 0;
		&:hover {
			color: $darkGrey;
			background-color: $lightGrey;
			border: 0;
		}
		&Disabled {
			color: #bebebe;
			border-color: transparent !important;
			&:hover {
				color: #bebebe;
			}
		}
	}
	label {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

:global {
	.with-filter {
		.basic-panel-content {
			height: calc(100% - 170px);
		}
	}
}

.basicPanelContent {
	padding: 24px 24px 0;
	position: relative;
	height: calc(100% - 95px); // 150 with filter
	&Tabs {
		height: calc(100% - 150px);
	}
	:global {
		.ms-ScrollablePane {
			margin: 0 24px 0;
		}
		.ms-DetailsHeader {
			padding-top: 0;
		}
		.ms-TextField {
			&-fieldGroup {
				height: 20px;
				border: 1px solid $backgroundGrey;
				input {
					font-size: 12px;
					padding: 0 5px;
				}
			}
		}
	}
}

.basicPanelFilter {
	display: flex;
	align-items: center;
	margin: 40px 24px 0;
	:global {
		.ms-TextField {
			width: 110px;
			margin-right: 15px;
			&.search-field {
				width: 220px;
			}
			&-fieldGroup {
				height: 28px;
			}
			&-suffix {
				cursor: pointer;
				background-color: $white;
				border-radius: 5px;
				i {
					font-size: 10px;
				}
			}
		}
	}
	& > div {
		margin-right: 15px;
	}
}

.basicPanelDetails {
	position: relative;
	height: 100%;
}