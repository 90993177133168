.addDefectModal {
	:global {
		.ms-Dialog-main {
			width: 800px;
			max-width: 800px;
			height: 80vh;
			// overflow-y: initial;
			.basic-modal-content {
				height: calc(80vh - 180px);
				overflow-y: auto;
			}
			.ms-TextField.code-field {
				width: 110px;
			}
			.total-count {
				margin-left: 15px;
			}
			.scrollable-warapper {
				position: relative;
				height: 85%;
				.ms-ScrollablePane--contentContainer {
					overflow-x: hidden;
				}
			}
			.scrollable-table-wrapper {
				position: relative;
				height: 90%;
				.loading-more {
					position: sticky;
					bottom: -15px;
					width: 100%;
				}
			}
		}
	}
	&.deviceHistoryModal {
		:global {
			.ms-Dialog-main {
				width: 1100px;
				max-width: 1100px;

				.scrollable-defects-wrapper {
					position: relative;
					height: 390px;
				}
			}
		}
	}
	:global {
		.ms-Pivot-link.is-disabled {
			background-color: rgb(243, 242, 241);
			cursor: not-allowed;
		}
	}
	.tabs {
		margin-top: 20px;
	}
}

.noScrollModal {
	:global {
		.ms-Dialog-main {
			overflow-y: initial;
			.scrollable-table-wrapper {
				height: 90%;
				overflow-y: scroll;
			}
		}
	}
}
.parentRow{
	:global{ 
		.ms-DetailsRow {
			font-weight: bold;
		}
	}
}