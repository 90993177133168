.expiringLicensesModal {
    :global {
        .ms-Dialog-main {
            width: 800px;
			max-width: 800px;
			height: 80vh;
            .basic-modal-content {
				height: calc(80vh - 180px);
				overflow-y: auto;
			}
			.ms-TextField.code-field {
				width: 110px;
			}
			.scrollable-warapper{
				position: relative;
				height: 85%;
				.ms-ScrollablePane--contentContainer{
					overflow-x: hidden;
				}
			}
			.scrollable-table-wrapper {
				position: relative;
				height: 90%;
				.loading-more {
					position: sticky;
					bottom: -15px;
					width: 100%;
				}
			}
        }
    }
}