
.basicToggle {
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	margin-bottom: 0;
	&inContainer {
		flex-direction: row;
	}
	&RowReverse {
		:global{
			.ms-Toggle-innerContainer{
				flex-direction: row-reverse;
				align-items: center;
			}
			.ms-Toggle-stateText{
				font-weight: 500;
			}
		}
	}
	:global {
		.ms-Label {
			margin-left: 7px;
		}
	}
}