@import "styles/constants.scss";

.loadingBlock {
  position: relative;
  flex: 4;
  height: 100%;
  :global {
    .ms-Spinner {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba($white, 0.7);
      min-height: 50px;
      z-index: 2;
      &-circle {
        width: 50px;
        height: 50px;
        border-width: 2.5px;
      }
    }
  }
}
