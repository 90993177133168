@import "styles/constants.scss";

.title,
.userName {
  font-weight: bold;
}

.activeLang {
  font-weight: bold;
}

.langItem {
  margin-left: 10px;
  cursor: pointer;
  text-transform: uppercase;
}

.langList {
  display: flex;
  align-items: center;
  list-style-type: none;
  margin-right: 30px;
}

.logoutButton {
  background-color: $white;
  color: $primaryColor;
  padding: 5px 10px;
  border-radius: 3px;
  border: 0;
  margin-left: 10px;
  cursor: pointer;
}

.switchBtn {
  &:hover {
    cursor: pointer;
  }
}

.user {
  display: flex;
  align-items: center;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  color: white;
  background-color: $primaryColor;
  height: 30px;
  &SearchButton {
    color: $white;
    margin-right: 20px;
    &:hover {
      color: $white;
      background: transparent;
    }
  }
}

.alerts {
  margin-right: 25px;
  font-size: 20px;
  cursor: pointer;
  margin-top: 1px;

  :global {
    .ms-TooltipHost {
      display: flex;
      align-items: center;
      position: relative;

      .count {
        font-size: 9px;
        font-weight: bold;
        color: $primaryColor;
        background-color: white;
        border-radius: 50%;
        text-align: center;
        width: 11px;
        height: 11px;
        padding: 2px;
        position: absolute;
        bottom: -5px;
        right: -7px;
      }
    }
  }

  &Callout {
    max-width: 320px;
    top: 42px !important;
  }

  &Clear {
    position: absolute;
    right: 10px;
    top: 7px;
    color: $primaryColor;
    font-size: 12px;

    &:hover {
      cursor: pointer;
    }
  }

  &Alert {
    margin: 10px 10px 0 10px;
    padding-bottom: 10px;
    display: flex;
    border-bottom: 1.5px solid #eee;

    &:last-child {
      border-bottom: 0;
    }
  }

  &Column {
    display: flex;
    flex-direction: column;
    padding: 5px;

    &:first-child {
      align-items: center;
      margin-right: 3px;
    }

    &Time,
    &Date {
      color: #aaa;
      margin-top: 2px;
    }

    i {
      color: #009688;
      font-size: 18px;
      margin-top: 8px;
      cursor: pointer;
    }

    &Title {
      font-size: 16px;
      margin-top: 2px;
    }

    &Message {
      margin-top: 2px;
      font-size: 12px;
      word-wrap: break-word;
    }
  }
}
