@import "/src/styles/constants.scss";

.faqPage {
  .filterWrapper {
    background-color: transparent;

    :global {
      .ms-TextField {
        width: 350px;
      }
    }
  }

  :global {
    .ms-List {
      width: 98%;
      &-cell {
        .group-row{
          padding: 15px;
          background-color: #f8f8f7;
          img {
            max-width: 100%;
            height: 240px;
            object-fit: contain;
          }
        }
        >div.ms-DetailsRow:hover {
          background-color: transparent;
        }
        .ms-DetailsRow-cell {
          white-space: initial;
          font-size: 14px;
        }
        .ms-GroupSpacer {
          display: none !important;
        }
        .ms-GroupedList-group {
          .ms-GroupHeader {
            background: rgb(243, 242, 241);
            margin: 5px 0;
            >div {
              display: flex;
              flex-direction: row-reverse;
              justify-content: space-between;

              .ms-GroupHeader-expand {
                i {
                  font-weight: 600;
                  &.is-collapsed {
                    transform: rotate(-90deg);
                  }
                }
              }
            }
            &-title {
              width: 100%;
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
  }

  .titleWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 0;

    span {
      font-weight: 600;
      white-space: pre-line;

      &.selectedPart {
        color: $primaryColor;
      }
    }

    .iconsWrapper {
      display: flex;

      i {
        display: flex;
        align-items: center;
        margin-left: 5px;
      }
    }

  }
}