.basicRadioGroup {
	:global {
		.ms-ChoiceFieldGroup-flexContainer {
			display: flex;
			gap: 8px;
			.ms-ChoiceField {
				margin-top: 0;
			}
		}
	}
}