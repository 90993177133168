@import "./constants.scss";

.ms-Callout-main {
	max-height: 580px !important;
	position: relative;

	.upper-content {
		padding: 0 8px 8px;
		position: sticky;
		top: 8px;
		z-index: 10;
		background-color: $white;
		margin-bottom: 8px;

		&::before {
			content: "";
			height: 10px;
			width: 100%;
			background-color: $white;
			position: absolute;
			top: -10px;
			left: 0;
		}

		.ms-TextField.is-active {
			.ms-TextField-fieldGroup {
				border-color: transparent;
			}
		}

		.ms-TextField-fieldGroup {
			background: rgb(243, 242, 241);
		}
	}

	.lower-content {
		display: flex;
		justify-content: flex-end;
		position: sticky;
		bottom: 0;
		left: 0;
		right: 20px;
		padding: 9px 0;
		background-color: $white;
		border-top: 2px solid rgb(243, 242, 241);

		.ms-Button {
			border: 0;

			&.is-disabled {
				background-color: transparent;
			}

			i {
				font-size: 10px;
			}
		}
	}

	.date-filter {
		width: 280px;
		display: flex;
		padding: 5px 0 5px 8px;

		.ms-TextField {
			padding-right: 8px;

			&-suffix {
				background-color: transparent;
				padding-left: 0;

				&:hover {
					cursor: pointer;
				}
			}
		}
	}

	.status {
		width: 21px;
		height: 21px;
		border-radius: 50%;
		margin-right: 6px;
		color: $white;
		font-size: 10px;
		font-weight: bold;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 5px;
	}

	.ms-Button-flexContainer {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.browse-organizations-callout {
	div {
		margin: 10px 15px;
		display: flex;
		align-items: center;
		font-size: 14px;
		cursor: pointer;
		&:hover {
			color: #009688;
		}
		img {
			margin-right: 10px;
		}
	}
}
