@import "styles/constants.scss";

.barcodeInput {
    width: 130px !important;
}

.active {
    :global {
        .ms-TextField-prefix {
            background-color: $primaryColor;
            color: $white;
        }
    }
}