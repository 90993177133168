@import "styles/constants.scss";

.redInput {
    :global {
        input {
            color: $red;
        }
    }
}

.amount {
    text-align: right;
    width: 100%;
}

.barcodeInputWrapper {
    height: 40px;
    display: flex;
    align-items: center;

}