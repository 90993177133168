@import "styles/constants.scss";

.pageWrapper {
  height: 100vh;

  &Content {
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: row;
  }
}

.container {
  padding: 0;
  height: 100%;
  overflow: hidden;
  flex: 4;
}

.haveUpdatesMessage {
  position: fixed;
  z-index: 1;
  bottom: 0;
  :global {
    .ms-MessageBar-content {
      padding: 9px 5px;
      .ms-MessageBar-text {
        font-size: 14px;
        u {
          cursor: pointer;
        }
      }
    }
  }
}
