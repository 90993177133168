.marker {
	width: 5px;
	height: calc(100% - 2px);
	display: block;
	margin-top: 1px;
	margin-bottom: 1px;
	&Wrapper {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		background: #fff;
	}
}
