@import "styles/constants.scss";

.widget {
    position: relative;
	padding: 15px 25px;
	border-radius: 10px;
	color: $white;
	background-color: #4cb5ab;
	width: 100%;
    height: 200px;
    text-decoration: none;
    box-sizing: border-box;
    :global {
        a {
            text-decoration: none;
            color: $white;
            display: flex;
            flex-direction: column;
            text-decoration: none;
        }
    }
}

.title {
    font-size: 20px;
    font-weight: bold;
}

.count {
    position: absolute;
    font-size: 60px;
    font-weight: bold;
    text-align: center;
    top: 57px;
    left: 0;
    right: 0;
}

.item {
    position: absolute;
    font-size: 18px;
    text-align: center;
    bottom: 20px;
    left: 0;
    right: 0;
}

.spinner {
    :global {
        .ms-Spinner-circle {
            width: 80px;
            height: 80px;
        }
    }
}