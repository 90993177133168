@import "styles/constants.scss";

.pageWrapperSearch {
  display: flex;
  align-items: center;
  background-color: #eee;
  margin-top: 10px;
  padding: 5px 10px 0;
  position: relative;
  overflow-x: auto;
  justify-content: flex-start;
  :global {
    .search-icon{
      cursor: pointer;
      margin-right: 10px;
    }
  }
}

.pageWrapperReportSearch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #eee;
  margin-top: 10px;
  padding: 10px;
  position: relative;
  overflow-x: auto;
  :global {
    .field > div {
      display: block;
      font-size: 14px;
      margin-bottom: 4px;
    }
  }
}

.pageWrapperReportActions {
  :global {
    .ms-Button {
      padding: 0 8px;
      max-width: 235px;
      height: 40px;
      margin-left: 10px;
    }
  }
}

.pageWrapperFields {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  :global {
    .ms-TextField {
      width: 110px;
      margin-right: 15px;
      margin-bottom: 5px;

      &.name-field {
        width: 220px;
      }

      &.code-field {
        width: 110px;
      }

      &-fieldGroup {
        height: 28px;
      }

      &-suffix {
        cursor: pointer;
        background-color: $white;
        border-radius: 5px;

        i {
          bottom: 4px;
          font-size: 10px;
        }
      }
    }
    .ms-ComboBox-container {
      margin-right: 15px;
      margin-bottom: 5px;
      .ms-ComboBox {
        height: 28px;
        border-radius: 4px;
      }
    }
    .ms-Checkbox, .ms-TooltipHost {
      margin-right: 15px;
      align-self: center;
      margin-bottom: 5px;
    }
    .ms-Button--default {
      height: 28px;
      margin-bottom: 5px;
    }

    .ms-Toggle {
      margin-right: 7px;
      margin-bottom: 5px;
    }
  }
}
