@import "./constants.scss";

.container {
    padding: 0;
    height: 100%;
    overflow: hidden;
    flex: 4;
}

.filterIsGlobal {
    outline: 1.5px solid $primaryLightColor;
    border-radius: 2px;
}

.columnWithTooltip {
    :global {
        .ms-TooltipHost {
            display: inline-block !important;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
