
.packageModal{
  :global {
		.ms-Dialog-main {
      width: 500px;
      max-width: 700px;
    }
  }
  .infoWrapper{
    &Descr{
      font-weight: bold;
      margin-left: 10px;
      .battery{
        display: flex;
        margin-bottom: 5px;
        .serialNumber{
          width: 100px;
        }
        .batteryName{
          text-overflow: ellipsis;
          overflow: hidden;
          text-wrap: nowrap;
          max-width: 300px;
        }
      }
    }
  }
}