.settingsBtn {
    margin-right: 25px;
    font-size: 20px;
    margin-top: 2px;
    cursor: pointer;
    &Items {
        list-style-type: none;
        padding-left: 0;
        margin: 15px 25px;
        li {
            cursor: pointer;
            &:hover {
                font-weight: bold;
            }
        }
    }
}
