@import 'styles/constants.scss';

.searchBySerial {
  :global {
    .ms-Dialog-main {
      max-width: 1100px !important;
      width: 100%;
    }
  }
}

.input {
  margin-left: 10px;
}

.table {
  &Section {
    margin-top: 40px;
  }
  &Wrapper {
    position: relative;
    overflow: auto;
    height: 280px;
  }
  &WrapperMedium{
    height: 225px;
  }
}

.findButton {
  margin-left: 5px;
}

.dotsLegendWrapper {
  margin-left: 9px;
  :global {
    .legendMarker {
      margin-right: 10px;
    }
  }
}

.label {
  margin-top: 7px;
}

.serialCell {
  &:hover {
    text-decoration: underline;
  }
}

.replaced {
  color: $blue;
}